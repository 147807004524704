import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SuggestedPlacesRoutingModule } from './suggested-places-routing.module';
import { SuggestedPlacesComponent } from './suggested-places.component';
import { SuggestedSideMenuComponent } from './suggested-side-menu/suggested-side-menu.component';
import { PlacesComponent } from './places/places.component';
import { PagesSharedModule } from '../pagesShared.module';


@NgModule({
  declarations: [SuggestedPlacesComponent, SuggestedSideMenuComponent, PlacesComponent],
  imports: [
    CommonModule,
    SuggestedPlacesRoutingModule,
    PagesSharedModule
  ]
})
export class SuggestedPlacesModule { }
