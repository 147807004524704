import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/shared/shared.service';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.scss']
})
export class PlacesComponent implements OnInit {

  constructor(public translate: TranslationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public sharedSer: SharedService,
    private route: ActivatedRoute,
     private pagesService: PagesService) { }

  @Input('places') places: any[];



  ngOnInit(): void {
    this.spinner.show();
    this.pagesService.getPlaces().subscribe(
      (response: any) => {
        console.log(response);
        this.places = response.data;
        console.log('placccesssss ===> ', this.places);
        this.spinner.hide();
      },
      (error) => {
        console.log(error);
      }
    )
  }

  onHandelPlace(place: any) {
    console.log(place);
    this.router.navigate(["../detail-place/" + place.id], {relativeTo: this.route})
  }

}
