import { Component, OnInit } from '@angular/core';
import { PagesService } from '../pages.service';

@Component({
  selector: 'app-suggested-places',
  templateUrl: './suggested-places.component.html',
  styleUrls: ['./suggested-places.component.scss']
})
export class SuggestedPlacesComponent implements OnInit {

  constructor(private pagesService: PagesService) { }

  placesData: any[] = [];

  ngOnInit(): void {
    this.pagesService.filtterSearchSuggestedPlacesObject.subscribe(
      (response: any) => {
        console.log(response);
        this.pagesService.searchPlaces(response).subscribe(
          (response: any) => {
            console.log(response);
            this.placesData = response.data;
          },
          (error) => {
            console.log(error);
          }
        )
      }
    )
  }

}
