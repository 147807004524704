import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-suggested-side-menu',
  templateUrl: './suggested-side-menu.component.html',
  styleUrls: ['./suggested-side-menu.component.scss']
})
export class SuggestedSideMenuComponent implements OnInit {

  constructor(private pagesService: PagesService,
     public translate: TranslationService) { }



  placesRules: any[];
  rolesId: number[] = [];

  countries: any[];
  //citieis data
  cities: any[];



  val:number = 0;
  val1:number = 1;
  val2:number = 2;
  val3:number = 3;
  val4:number = 4;
  val5: number = 5;

  isCheckedRate0: boolean = false;
  isCheckedRate1: boolean = false;
  isCheckedRate2: boolean = false;
  isCheckedRate3: boolean = false;
  isCheckedRate4: boolean = false;
  isCheckedRate5: boolean = false;

  ngOnInit(): void {
    this.pagesService.getPlacesRules().subscribe(
      (response: any) => {
        this.placesRules = response.data;
        console.log(this.placesRules);
      },
      (error) => {
        console.log(error);
      }
    );
    this.pagesService.getCountry().subscribe(
      (response: any) => {
        this.countries = response.data;
        console.log('=====> ', this.countries);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  //handelChangeCountry
  handelChangeCountry(e: any) {
    console.log('===>', e.target.value);
    this.getCities(e.target.value);
  }

  //handelChangeCity
  handelChangeCity(e: any) {
    console.log(e.target.value == 'not');
    if(e.target.value != 'not') {
      this.pagesService.searchObjectPlaces = {...this.pagesService.searchObjectPlaces,
        cityId: +e.target.value
      };
    } else {
      delete this.pagesService.searchObjectPlaces.cityId;
      this.pagesService.searchObjectPlaces = {
        ...this.pagesService.searchObjectPlaces,
      };
    }
    this.pagesService.filtterSearchSuggestedPlacesObject.next(this.pagesService.searchObjectPlaces);
  }

  //getCities
  getCities(countryId: number) {
    this.pagesService.getCity(countryId).subscribe(
      (response: any) => {
        console.log(response);
        this.cities = response.data;
      },
      (error) => {
        console.log(error);
      }
    )
  };

  handelChangeRate(rateCheck: boolean, type: string) {
    console.log(rateCheck, type);

    if(!rateCheck) {
      delete this.pagesService.searchObjectPlaces.rate;
      this.pagesService.filtterSearchSuggestedPlacesObject.next(this.pagesService.searchObjectPlaces);
      return;
    };

    //add rate of 0 to search object
    if(rateCheck && type == 'first') {
      this.pagesService.searchObjectPlaces = {
        ...this.pagesService.searchObjectPlaces,
        rate: this.val
      };
      this.pagesService.filtterSearchSuggestedPlacesObject.next(this.pagesService.searchObjectPlaces);
    }

    //add rate of 1 to search object
    if(rateCheck && type == 'second') {
      this.pagesService.searchObjectPlaces = {
        ...this.pagesService.searchObjectPlaces,
        rate: this.val1
      };
      this.pagesService.filtterSearchSuggestedPlacesObject.next(this.pagesService.searchObjectPlaces);
    }

     //add rate of 2 to search object
    if(rateCheck && type == 'third') {
      this.pagesService.searchObjectPlaces = {
        ...this.pagesService.searchObjectPlaces,
        rate: this.val2
      };
      this.pagesService.filtterSearchSuggestedPlacesObject.next(this.pagesService.searchObjectPlaces);
    }

    //add rate of 3 to search object
    if(rateCheck && type == 'fourth') {
      this.pagesService.searchObjectPlaces = {
        ...this.pagesService.searchObjectPlaces,
        rate: this.val3
      };
      this.pagesService.filtterSearchSuggestedPlacesObject.next(this.pagesService.searchObjectPlaces);
    }
    //add rate of 4 to search object
    if(rateCheck && type == 'five') {
      this.pagesService.searchObjectPlaces = {
        ...this.pagesService.searchObjectPlaces,
        rate: this.val4
      };
      this.pagesService.filtterSearchSuggestedPlacesObject.next(this.pagesService.searchObjectPlaces);
    }

    //add rate of 5 to search object
    if(rateCheck && type == 'six') {
      this.pagesService.searchObjectPlaces = {
        ...this.pagesService.searchObjectPlaces,
        rate: this.val5
      };
      this.pagesService.filtterSearchSuggestedPlacesObject.next(this.pagesService.searchObjectPlaces);
    }

  }

  //hanedlChangeName
  hanedlChangeName(event: any) {
    console.log("yasser");

    console.log(event.target.value);
    const name = event.target.value;
    if(name.length > 0) {
      this.pagesService.searchObjectPlaces = {
        ...this.pagesService.searchObjectPlaces,
        name: name
      }
    } else {
      delete this.pagesService.searchObjectPlaces?.name;
      this.pagesService.searchObjectPlaces = {
        ...this.pagesService.searchObjectPlaces,
      }
    }

    this.pagesService.filtterSearchSuggestedPlacesObject.next(this.pagesService.searchObjectPlaces);
  }

  handelChangeChecked(type: any) {
    console.log(type);
    const index = this.rolesId.findIndex(id => id == type.id);
    if(type.isChecked && index == -1) {
      this.rolesId.push(type.id);
    } else {
      this.rolesId.splice(index, 1);
    }
    console.log(this.rolesId);
    if(this.rolesId.length > 0) {
      this.pagesService.searchObjectPlaces = {
        ...this.pagesService.searchObjectPlaces,
        roles: this.rolesId
      };
    } else {
      delete this.pagesService.searchObjectPlaces.roles;
      this.pagesService.searchObjectPlaces = {
        ...this.pagesService.searchObjectPlaces,
      }
    }

    this.pagesService.filtterSearchSuggestedPlacesObject.next(this.pagesService.searchObjectPlaces);
  }

}
