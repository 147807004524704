<div [ngClass]="{'sideMenu': translate.is_arabic, 'sideMenuEn': !translate.is_arabic}">
    <div class="header sticky-top d-md-block d-none">
      <p>{{translate.data.result.filttring}}</p>
    </div>

    <div class="header d-md-none d-block" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
      <p>{{translate.data.result.filttring}}</p>
    </div>

    <div class="body collapse d-md-inline-block" id="collapseExample">

      <div class="form-floating mb-3">
        <input type="text"
                class="form-control"
                id="floatingInput"
                (keyup.enter)="hanedlChangeName($event)"
                placeholder="name@example.com">
        <label for="floatingInput">{{translate.is_arabic ? 'الإسم' : 'Name'}}</label>
      </div>

      <div class="reactiveC">
       <img class="img" src="../../../../assets/Content/location-pin/location-pin.png" />
        <select (change)="handelChangeCountry($event)">
            <option>{{translate.is_arabic ? 'الدوله' : 'Country'}}</option>
            <option *ngFor="let country of countries" [value]="country.id">
                {{country[translate.data.requestdData.genName]}}
            </option>
        </select>
      </div>
      <div class="reactiveC">
         <img class="img" src="../../../../assets/Content/location-pin/location-pin.png" />
          <select (change)="handelChangeCity($event)">
              <option [value]="'not'">{{translate.is_arabic ? 'المدينه' : 'City'}}</option>
              <option *ngFor="let city of cities" [value]="city.id">
                  {{city[translate.data.requestdData.genName]}}
              </option>
          </select>
      </div>


      <p class="title">
        {{translate.is_arabic ? 'قواعد المكان' : 'Place Rules'}}
      </p>

      <div class="form-check" *ngFor="let rule of placesRules">
        <input class="form-check-input" type="checkbox"
              [(ngModel)]="rule.isChecked"
              (change)="handelChangeChecked(rule)">
        <label class="form-check-label label">
          {{rule[translate.data.requestdData.genName]}}
        </label>
     </div>

    <p class="rating">{{translate.data.result.rating}}</p>

    <div>
      <div class="form-check" style="margin: 0 15px;  margin-left: 0;">
        <input class="form-check-input"
         type="checkbox"
         id="flexCheckChecked"
         [(ngModel)]="isCheckedRate5"
         (change)="handelChangeRate(isCheckedRate5, 'six')"
         style="margin: 7px -17px;">
       <star-rating [value]="val5"
                  [totalstars]="5"
                  checkedcolor="#ed6c37"
                  uncheckedcolor="rgb(224, 224, 224)"
                  size="24px"
                  [readonly]="true">
        </star-rating>
      </div>
      <div class="form-check" style="margin: 0 15px; margin-left: 0;">
        <input class="form-check-input"
         type="checkbox"
        [(ngModel)]="isCheckedRate4"
        (change)="handelChangeRate(isCheckedRate4, 'five')"
        id="flexCheckChecked"
        style="margin: 7px -17px;">
       <star-rating [value]="val4"
                  [totalstars]="5"
                  checkedcolor="#ed6c37"
                  uncheckedcolor="rgb(224, 224, 224)"
                  size="24px"
                  [readonly]="true">
        </star-rating>
      </div>
      <div class="form-check" style="margin: 0 15px;  margin-left: 0;">
        <input class="form-check-input"
         type="checkbox"
         id="flexCheckChecked"
         style="margin: 7px -17px;"
         (change)="handelChangeRate(isCheckedRate3, 'fourth')"
        [(ngModel)]="isCheckedRate3">
        <star-rating [value]="val3"
                    [totalstars]="5"
                    checkedcolor="#ed6c37"
                    uncheckedcolor="rgb(224, 224, 224)"
                    size="24px"
                    [readonly]="true">
        </star-rating>
      </div>
      <div class="form-check" style="margin: 0 15px;  margin-left: 0;">
        <input class="form-check-input"
               type="checkbox"
               id="flexCheckChecked"
               [(ngModel)]="isCheckedRate2"
               (change)="handelChangeRate(isCheckedRate2, 'third')"
                style="margin: 7px -17px;">
        <star-rating [value]="val2"
                      [totalstars]="5"
                      checkedcolor="#ed6c37"
                      uncheckedcolor="rgb(224, 224, 224)"
                      size="24px"
                      [readonly]="true">
        </star-rating>
      </div>

        <div class="form-check" style="margin: 0 15px;  margin-left: 0;">
          <input class="form-check-input"
                type="checkbox"
                [(ngModel)]="isCheckedRate1"
                (change)="handelChangeRate(isCheckedRate1, 'second')"
                id="flexCheckChecked"
                style="margin: 7px -17px;">
          <star-rating [value]="val1"
                        [totalstars]="5"
                        checkedcolor="#ed6c37"
                        uncheckedcolor="rgb(224, 224, 224)"
                        size="24px"
                        [readonly]="true">
          </star-rating>
        </div>
        <div class="form-check" style="margin: 0 15px;  margin-left: 0;">
          <!-- <input class="form-check-input"
                  [(ngModel)]="isCheckedRate0"
                  (change)="handelChangeRate(isCheckedRate0, 'first')"
                  type="checkbox"
                  id="flexCheckChecked"
                  style="margin: 7px -17px;"> -->
          <!-- <p-rating [(ngModel)]="val" [cancel] = "false"></p-rating> -->
     <!--      <star-rating [value]="val"
                        [totalstars]="5"
                        checkedcolor="#ed6c37"
                        uncheckedcolor="rgb(224, 224, 224)"
                        size="24px"
                        [readonly]="true">
          </star-rating> -->
        </div>


      </div>


  </div>
</div>

