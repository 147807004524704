import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SuggestedPlacesComponent } from './suggested-places.component';

const routes: Routes = [
  {path: '', component: SuggestedPlacesComponent, children: [
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuggestedPlacesRoutingModule { }
