
<div [ngClass]="{'search-result': translate.is_arabic, 'search-resultEn': !translate.is_arabic}">
    <div class="header-result sticky-md-top">
        <div class="row">
            <div class="col-12 col-md-4">
                <p>{{translate.data.suggestedPlaces.suggestedPlaces}}
                    <span> {{translate.data.suggestedPlaces.totalNumber}} : {{places ? places.length : 0}}</span>
                </p>
            </div>
        </div>
    </div>
    <div class="karavan-result">
      <div class="row">
          <h1 class="text-center" *ngIf="!places">{{translate.is_arabic ? 'لاتوجد بيانات لعرضها' : 'No Data To Show !'}}</h1>
          <div class="col-12 col-md-4" *ngFor="let place of places" (click)="onHandelPlace(place)">
              <div class="data-card">
                  <div class="img">
                      <img src="{{sharedSer.ipRootImg + place.photo}}" alt="" title="" loading="lazy"/>
                  </div>
                  <div class="detail">
                    <h1>{{place ? place[translate.data.requestdData.genName] : 'N/A'}}</h1>
                    <p>  <star-rating [value]="5"
                        [totalstars]="5"
                        checkedcolor="#ed6c37"
                        uncheckedcolor="rgb(224, 224, 224)"
                        size="18px"
                        [readonly]="true">
                      </star-rating></p>
                    <span style="font-size: 12px;"><img style="height: 20px; margin-left: 10px;"
                        src="../../../../assets/Content/location-pin/location-pin.png"/>{{place ? place[translate.data.requestdData.genCountry] : 'N/A'}} / {{place ? place[translate.data.requestdData.genCity] : 'N\A'}}</span>
                    <button>{{translate.is_arabic ? 'عرض التفاصيل' : 'Show Detail'}}</button>
                  </div>
              </div>
         </div>
      </div>
    </div>
</div>


